import React from "react"
import './App.css';
import Status from "./Status";
import statusData from "./burnerdata"
import BarGraph from "./BarGraph"

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      stats: statusData,
      realStats: {}
    }
  }

  componentDidMount() {
    fetch("https://api.hofft.dev/status", { method: 'GET', headers: {'Accept': 'application/json'}})
      .then(response => response.json())
      .then(data => {
        this.setState({
          realStats: data
        })
      })
  }

 
//figure this one out, either reformat the api data into and array of responses or make it nice on the front end
  
  render()  {
    const statusComponents = this.state.stats.map(data => <Status stat={data}/>)
    console.log(Object.keys(this.state.realStats))

    
    

    return (
      <div className="App">
        {statusComponents}
        <p>{this.state.realStats.Characters}</p>
        {<BarGraph />}
      </div>
    );
  }
   
}

export default App;
