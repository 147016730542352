import React from "react"

function Status(props) {
    return (
        <div>
            <h2>{props.stat.id}</h2>
            <h2>{props.stat.chars}</h2>
            <h2>{props.stat.shiptype}</h2>
        </div>
    )
}

export default Status