import React, {Component} from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)


class BarGraph extends Component{
    constructor() {
        super()
        this.state = {
          myData: {},
          LabelData: {},
          isLoaded: false
        }
      }

      componentDidMount() {
        fetch("https://api.hofft.dev/count", { method: 'GET', headers: {'Accept': 'application/json'}})
          .then(response => response.json())
          .then(data => {
            this.setState({
              myData: Object.values(data),
              LabelData: Object.keys(data),
              isLoaded: true
            })
          })
      }
    
    
    
    render(){
        //pretty much need to list each info point as a shipName, Ship thing so I can get all the right data here...
        //https://codepen.io/bencarmichael/pen/XeYJXJ probably cant split this one up actually rip
        const GraphData = {
                labels: this.state.LabelData,
                datasets: [
                    {
                        label: 'Total capitals',
                        data: this.state.myData,
                    },
                ],

            }

            const options = {
                grouped: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }
        
        
        console.log(GraphData)
        console.log(this.state.myData[3])
        return (
            
            <div className="ChartBar">
                {this.state.isLoaded ? <Bar data={GraphData} options={options} /> : <div> Still Loading </div>}
            </div>
        )
    }
}



export default BarGraph